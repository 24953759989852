import { createApp } from "vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from './utils/http'
import comm from './utils/common'
import api from './api/index'
import moment from "moment";
import * as echarts from 'echarts'
import './assets/css/reset.css'


const app = createApp(App)
app.config.productionTip = false

app.config.globalProperties.$http = http;
app.config.globalProperties.$comm = comm;
app.config.globalProperties.$api = api;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$moment = function (input, formatString = 'YYYY-MM-DD') {
  if (!input) {
    return ''
  }
  return moment(input).format(formatString)
}

app.use(ElementPlus);
app.use(ElementPlus, {
  locale: zhCn,
})

app.use(store).use(router).mount("#app");
app.echarts = echarts
