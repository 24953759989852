export default {
  httpPorts: {
    // 登录
    captchaImage: {
      url: "/smsUser/captchaImage",
      method: "GET",
      isToken: 0,
      name: "获取验证码",
    },
    login: {
      url: "/smsUser/login",
      method: "POST",
      isDownload: false, // true为下载,
      name: "登录",
      isToken: 0, // 不需要 1需要(默认 un) 2：皆可
      fallNUL: false, // 是否需要去掉值为空字符的参数
    },
    changePwd: {
      url: "/smsUser/changePwd",
      method: "POST",
      name: "修改密码",
    },
    // 短信模板
    getTemplateList: {
      url: "/smsTemplate/getTemplateList",
      method: "POST",
      name: "查询短信模板列表",
    },
    addTemplate: {
      url: "/smsTemplate/addTemplate",
      method: "POST",
      name: "新增短信模板",
    },
    deleteTemplate: {
      url: "/smsTemplate/deleteTemplate",
      method: "POST",
      name: "删除短信模板",
    },
    // 任务
    getTaskId: {
      url: "/smsTask/getTaskId",
      method: "GET",
      name: "获取添加任务的id",
    },
    upload: {
      url: "/smsTask/upload",
      method: "POST",
      name: "上传电话本",
      // isUpLoad: true
    },
    addTask: {
      url: "/smsTask/addTask",
      method: "POST",
      name: "添加任务",
    },
    getTaskList: {
      url: "/smsTask/getTaskList",
      method: "POST",
      name: "任务列表",
    },
    getTaskDetails: {
      url: "/smsTaskDetails/getTaskDetails",
      method: "POST",
      name: "任务详情列表",
    },
    // 充值记录
    getRechargeList: {
      url: "/smsRecharge/getRechargeList",
      method: "POST",
      name: "短信充值记录列表",
    },
    updateUser: {
      url: "/smsUser/updateUser",
      method: "POST",
      name: "新增充值记录",
    },
    // 统计
    getNumber: {
      url: "/smsUser/getNumber",
      method: "GET",
      name: "获取当前用户剩余条数",
    },
    getStatistics: {
      url: "/smsTask/getStatistics",
      method: "GET",
      name: "获取发送号码数和计费条数",
    },
    getDataStatistics: {
      url: "/smsTask/getDataStatistics",
      method: "POST",
      name: "获取统计列表和统计图",
    },
    queryTaskTemplateReport: {
      url: "/smsTask/queryTaskTemplateReport",
      method: "POST",
      isDownload: true, // true为下载,
      name: "统计月列表模板导出",
    },
    // 用户
    getUserList: {
      url: "/smsUser/getUserList",
      method: "POST",
      name: "用户列表",
    },
    // 短信自定义发送
    addCustTask: {
      url: "/smsTask/addCustTask",
      method: "POST",
      name: "短信自定义发送",
    },
    updateStatus: {
      url: "/smsTemplate/updateStatus",
      method: "POST",
      name: "短信审核",
    },
    addUser: {
      url: "/smsUser/addUser",
      method: "POST",
      name: "新增用户",
    },
    updateU: {
      url: "/smsUser/updateU",
      method: "POST",
      name: "编辑用户",
    },
    deleteU: {
      url: "/smsUser/deleteU",
      method: "POST",
      name: "删除用户",
    },
    // 闪信
    getFlashList: {
      url: "/FlashConfiguration/getFlashList",
      method: "POST",
      name: "列表",
    },
    updateFlashU: {
      url: "/FlashConfiguration/updateU",
      method: "POST",
      name: "修改",
    },
  },
};
