/* 函数处理 */
// 深复制 除函数
function deepCopy(i) {
  if (typeof i === 'object') {
    return JSON.parse(JSON.stringify(i))
  } else {
    return i
  }
}
// 转化为数组 无布尔 //逗号分隔
function splitArr(i) {
  if (i) {
    if (Array.isArray(i)) {
      return i
    } else {
      return i.split(',')
    }
  } else {
    return []
  }
}
// 改变字体颜色-关键字 文本 颜色
function changeFontColor(searchText, text, color = 'red') {
  const res = new RegExp('(' + searchText + ')', 'g')
  text = text.replace(
    res,
    "<span style='color:" + color + ";'>" + searchText + '</span>'
  )
  return text
}
// 多次下载文件
function downloadFile(url) {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none' // 防止影响页面
  iframe.style.height = 0 // 防止影响页面
  iframe.src = url
  document.body.appendChild(iframe) // 这一行必须，iframe挂在到dom树上才会发请求
  setTimeout(() => {
    iframe.remove()
  }, 5 * 60 * 1000)
}
// http转换为https
function changhttp(ta) {
  if (!ta) {
    return ''
  }
  if (ta.length > 5 && ta.indexOf('https') != 0 && ta.indexOf('http') == 0) {
    ta = ta.replace(/^http/, 'https')
  }
  return ta
}
/* 根据身份证获取出生年月日 */
function Birthday(UUserCard) {
  // 判断身份证是否正确
  const y = UUserCard.substring(6, 10)
  const m = UUserCard.substring(10, 12)
  const d = UUserCard.substring(12, 14)
  const Bir = y + '-' + m + '-' + d
  return Bir
}
/** 根据身份证计算年龄 */
function IdCardAgeRequest(UUserCard) {
  // 获取年龄
  if (UUserCard.length < 10) {
    return ''
  }
  var myDate = new Date()
  var month = myDate.getMonth() + 1
  var day = myDate.getDate()
  var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
  if (
    UUserCard.substring(10, 12) < month ||
    (UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day)
  ) {
    age++
  }
  return age
}
// 获取年月日 时分秒
function getDate(T) {
  return
  const y = T.getFullYear()
  const m = T.getMonth() + 1 < 10 ? '0' + (T.getMonth() + 1) : T.getMonth() + 1
  const d = T.getDate() < 10 ? '0' + T.getDate() : T.getDate()
  const q = T.getHours() < 10 ? '0' + T.getHours() : T.getHours()
  const w = T.getMinutes() < 10 ? '0' + T.getMinutes() : T.getMinutes()
  const e = T.getSeconds() < 10 ? '0' + T.getSeconds() : T.getSeconds()
  return {
    // 年月日
    YYYY: y,
    MM: m,
    DD: d,
    d: q,
    e: w,
    f: e
  }
}
// 按钮点击 请求时触发的函数及参数 update?id&status"
function buttonPower(i) {
  if (i) {
    i = i.split('?')
    const url = i[0]
    let list = []
    if (i[1]) {
      list = i[1].split('&')
    }
    return { fun: url, list: list }
  }
}

// 星号处理名称
function noPassByName(str) {
  if (str != null && str != undefined) {
    if (str.length == 1) {
      return str
    } else if (str.length <= 3 && str.length > 1) {
      return '*' + str.substring(1, str.length)
    } else if (str.length > 3 && str.length <= 6) {
      return '**' + str.substring(2, str.length)
    } else if (str.length > 6 && str.length <= 8) {
      return str.substring(0, 2) + '****' + str.substring(6, str.length)
    } else if (str.length > 8) {
      // 175 **** 1995
      return str.substring(0, 3) + '****' + str.substring(7, str.length)
    }
  } else {
    return ''
  }
}
// 日期比较 开始 结束
function dateXsdate(date1, date2) {
  if (!date1 || !date2) {
    return true
  }
  if (new Date(date1) > new Date(date2)) {
    return false
  }
  return true
}
// 字符串转base64
function encode(str) {
  if (!str) {
    return str
  }
  // 对字符串进行编码
  var encode = encodeURI(str)
  // 对编码的字符串转化base64
  var base64 = btoa(encode)
  return base64
}

// base64转字符串
function decode(base64) {
  if (!base64) {
    return base64
  }
  // 对base64转编码
  var decode = atob(base64)
  // 编码转字符串
  var str = decodeURI(decode)
  return str
}
function isPhone(val) {
  var reg = new RegExp(/^1(3|4|5|7|8|9)\d{9}$/)
  if (!reg.test(val)) {
    return false
  } else {
    return true
  }
}
/**************************************时间格式化处理************************************/
function dateFtt(fmt, date) { //author: meizz 
  var o = {
    "M+": date.getMonth() + 1,     //月份 
    "d+": date.getDate(),     //日 
    "h+": date.getHours(),     //小时 
    "m+": date.getMinutes(),     //分 
    "s+": date.getSeconds(),     //秒 
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
    "S": date.getMilliseconds()    //毫秒 
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
export default {
  deepCopy,
  buttonPower,
  dateXsdate,
  noPassByName,
  encode,
  decode,
  isPhone,
  dateFtt
}
