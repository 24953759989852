import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    redirect: "/login",
    meta: {
      title: "账号登录"
    },
    component: () => import("../views/Login.vue")
  },
  {
    path: '/login',
    name: "login",
    meta: {
      title: "账号登录"
    },
    component: () => import("../views/Login.vue")
  },
  {
    path: '/home',
    name: 'home',
    //这里是核心： center作为容器组件，包含左右菜单和右侧的router-view，所以下面的子组件实际是显示在router-view
    component: () => import("../views/Home.vue"),
    redirect: { name: "messageAdd" },   //输入路由center会重定向到a页面
    children: [
      {
        path: "/messageAdd",
        name: "messageAdd",
        meta: {
          title: "短信发送"
        },
        component: () => import("../views/Message/messageAdd.vue"),
      },
      {
        path: "/messageList",
        name: "messageList",
        meta: {
          title: "模板管理"
        },
        component: () => import("../views/Message/messageList.vue"),
      },
      {
        path: "/messageTaskList",
        name: "messageTaskList",
        meta: {
          title: "短信任务"
        },
        component: () => import("../views/Message/messageTaskList.vue"),
      },
      {
        path: "/messageTaskInfoList",
        name: "messageTaskInfoList",
        meta: {
          title: "短信任务详情"
        },
        component: () => import("../views/Message/messageTaskInfoList.vue"),
      },
      {
        path: "/messageChart",
        name: "messageChart",
        meta: {
          title: "短信统计"
        },
        component: () => import("../views/Chart/messageChart.vue"),
      },
      {
        path: "/password",
        name: "password",
        meta: {
          title: "修改密码"
        },
        component: () => import("../views/System/password.vue"),
      },
      {
        path: "/chargeList",
        name: "chargeList",
        meta: {
          title: "充值记录"
        },
        component: () => import("../views/System/chargeList.vue"),
      },
      {
        path: "/users",
        name: "users",
        meta: {
          title: "用户列表"
        },
        component: () => import("../views/System/users.vue"),
      },
      // {
      //   path: "/flashList",
      //   name: "flashList",
      //   meta: {
      //     title: "闪信管理"
      //   },
      //   component: () => import("../views/Flash/index.vue"),
      // },
    ],
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
